// import {useHistory} from 'react-router-dom';
import {format, parseISO} from 'date-fns';

import Typography from 'components/Typography';
import Back from 'components/Back';
// import IconButton from 'components/IconButton';
// import StatusBadge from 'components/StatusBadge';

import {ScriptStatus} from 'types';
// import {mapStatusColor, mapStatusText} from 'constants/mappings';

import styles from '../RequestDetails.module.scss';
import Button from 'components/Button';
import {useHistory} from 'react-router-dom';
import api from 'api';
import toast from 'react-hot-toast';
import {get} from 'lodash';
import Modal from 'components/Modal';
import {useState} from 'react';
import ScriptStatusSelect from 'components/ScriptStatusSelect';

interface Props {
  id: string | number;
  order_id: string | number;
  drugId: number;
  status: ScriptStatus;
  created_at: string;
  app: string;
  completeDisabled: boolean;
  previous_id?: number;
  next_id?: number;
  complete: () => void;
  origin: string;
  onChangeStatus: (data: any, action: any) => void;
}

function Header({
  id,
  order_id,
  drugId,
  status,
  created_at,
  app,
  origin,
  onChangeStatus,
}: // completeDisabled,
// previous_id,
// next_id,
// complete,
Props) {
  const [deleteVisible, setIsDeleteVisible] = useState(false);
  const datetime = parseISO(created_at);
  const {goBack} = useHistory();

  const deleteOrder = async () => {
    try {
      await api.delete(`/requests/${order_id}/delete/`);
      setIsDeleteVisible(false);
      goBack();
    } catch (error) {
      setIsDeleteVisible(false);
      toast.error(get(error, 'response.data.error_message', 'Error'));
    }
  };

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.title_wrapper}>
          <Back />
          <Typography className={styles.title} variant="h2">
            {app === 'app' ? 'Request' : 'Order'} #{id}
          </Typography>
          <ScriptStatusSelect
            status={status}
            name={`drug-${drugId}`}
            onChange={onChangeStatus}
            type="prescription"
            origin={origin}
            isPvfr={true}
            // isPvfr={drugs.filter((item) => !item.is_pvrf_done).length === 0}
            isDisabled={status === 'delivered'}
          />
          {/* <StatusBadge color={mapStatusColor[status]}>
            {mapStatusText[status]}
          </StatusBadge> */}
        </div>

        <Typography className={styles.subtitle} variant="h5">
          {format(datetime, 'MMMM d, yyyy')}
        </Typography>
      </div>
      <div className={styles.buttons}>
        <Button onClick={() => setIsDeleteVisible(true)} color="danger">
          Delete order
        </Button>
        {/*{status !== 'completed' && (*/}
        {/*  <Button*/}
        {/*    className={styles.complete_btn}*/}
        {/*    color="green"*/}
        {/*    disabled={completeDisabled}*/}
        {/*    onClick={complete}*/}
        {/*  >*/}
        {/*    Mark transfers as complete*/}
        {/*  </Button>*/}
        {/*)}*/}
        {/* <IconButton
          icon="arrow_left"
          disabled={!previous_id}
          onClick={() => push(`/requests/${previous_id}`)}
        />
        <IconButton
          icon="arrow_right"
          disabled={!next_id}
          onClick={() => push(`/requests/${next_id}`)}
        /> */}
      </div>
      <Modal
        className={styles.deleteModal}
        isOpen={deleteVisible}
        onClose={() => setIsDeleteVisible(false)}
      >
        <Typography component="p" variant="h2">
          Are you sure you want to delete?
        </Typography>
        <div className={styles.optionWrapper}></div>
        <div className={styles.actions}>
          <Button
            color="gray"
            size="md"
            onClick={() => setIsDeleteVisible(false)}
          >
            Close
          </Button>
          <Button color="danger" onClick={deleteOrder} size="md">
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Header;

import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';

import {Drug, SelectOption} from 'types';

import styles from '../RequestDetails.module.scss';
import ScriptStatusSelect, {
  ScriptStatusOption,
} from 'components/ScriptStatusSelect';
import {ActionMeta, SingleValue} from 'react-select';

interface Props {
  drugs: Drug[];
  origin?: string;
  onChangeStatus: (
    newValue: SingleValue<ScriptStatusOption>,
    actionMeta: ActionMeta<SelectOption>
  ) => void;
}

function Otcs({drugs, origin, onChangeStatus}: Props) {
  if (drugs.length === 0) return null;

  return (
    <Card>
      <CardContent px="sm" pt="md" pb="sm">
        <Typography component="p" variant="label">
          OTCs
        </Typography>
        {drugs.map((drug) => (
          <div key={drug.id} className={styles.otc}>
            <Typography
              component="p"
              variant="h3"
              className={styles.drug__name}
            >
              {drug.name}
            </Typography>
            <Typography component="p" variant="label">
              {drug.strength}
            </Typography>
            <div className={styles.drugActions}>
              <ScriptStatusSelect
                name={`drug-${drug.id}`}
                status={drug.status}
                onChange={onChangeStatus}
                type="otc"
                origin={origin}
              />
            </div>
            <div className={styles.payments}>
              <div className={styles.payment}>
                <Typography variant="label" className={styles.payment__label}>
                  Amount due
                </Typography>
                <Typography variant="strong" className={styles.payment__value}>
                  ${drug.amount_due || 0}
                </Typography>
              </div>
              <div className={styles.payment}>
                <Typography variant="label" className={styles.payment__label}>
                  Paid
                </Typography>
                <Typography variant="strong" className={styles.payment__value}>
                  ${drug.paid || 0}
                </Typography>
              </div>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

export default Otcs;

import {Controller} from 'react-hook-form';

import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import SelectField from 'components/Select';
import ListWrapper from 'components/ListWrapper';
import CardContent from 'components/CardContent';

import {validateEmail} from 'utils/validators';

import useEmployeeForm from './useEmployeeForm';

import styles from './EmployeeForm.module.scss';
import roles from 'constants/roles';
import {SetStateAction, useCallback, useState} from 'react';
import useDeleteEmployee from './useDeleteEmployee';
import {useHistory} from 'react-router-dom';
import EyeIcon from './icons/eye.svg';
import EyeDisabledIcon from './icons/eye-disable.svg';
import Modal from 'components/Modal';
import VehicleForm from 'pages/VehicleForm';
// import {PlusComponent} from 'pages/Requests/Requests';
import ReactSwitch from 'react-switch';

type Props = {
  isModal?: boolean;
  setUser?: (data: any) => void;
  setModalVisible?: (data: SetStateAction<boolean>) => void;
};

function EmployeeForm({
  isModal,
  setUser,
  setModalVisible: setHideTeamModal,
}: Props) {
  const {
    role,
    employeeId,
    submitting,
    errors,
    control,
    register,
    handleSubmit,
    setTeam,
    watch,
    setValue,
  } = useEmployeeForm(!!isModal, setUser);
  const history = useHistory();
  const {deleteEmployee, isDeleting} = useDeleteEmployee();
  const [modalVisible, setModalVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      await deleteEmployee(id);
      history.replace('/employees');
    },
    [deleteEmployee, history]
  );

  return (
    <div>
      <PageHeader>
        <Typography variant="h2">
          {employeeId ? 'Edit Employee' : 'New Employee'}
        </Typography>
        {!isModal && (
          <FormActionGroup>
            {!!employeeId && (
              <Button
                onClick={() => handleDeleteEmployee(+employeeId)}
                color="danger"
                loading={isDeleting}
              >
                Delete
              </Button>
            )}
            <Button onClick={() => history.goBack()} color="gray">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="green" loading={submitting}>
              Save
            </Button>
          </FormActionGroup>
        )}
      </PageHeader>
      <ListWrapper>
        <CardContent px="md" pt="none" pb="md">
          <FormGroup columns={2}>
            <TextField
              label="First name"
              placeholder="First name"
              {...register('first_name', {required: 'Required'})}
              error={errors.first_name?.message}
            />
            <TextField
              label="Last name"
              placeholder="Surname"
              {...register('last_name', {required: 'Required'})}
              error={errors.last_name?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Email address"
              placeholder="Email address"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="Phone number"
              {...register('phone')}
              error={errors.phone?.message}
            />
          </FormGroup>

          {role !== 'management' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
                justifyContent: 'flex-end',
              }}
            >
              <p>Status:</p>
              <label>
                <ReactSwitch
                  onColor="#ab20fd"
                  onChange={(val) => setValue('is_active', val)}
                  handleDiameter={22}
                  height={26}
                  width={45}
                  checked={watch('is_active')}
                />
              </label>
            </div>
          )}

          {/* <FormGroup columns={2}>
            <TextField
              label="Address line 1"
              placeholder="Street"
              {...register('address_line_1')}
              error={errors.address_line_1?.message}
            />
            <TextField
              label="Address line 2"
              placeholder="Apartment"
              {...register('address_line_2')}
              error={errors.address_line_2?.message}
            />
          </FormGroup>

          <FormGroup columns={3}>
            <TextField
              label="City"
              placeholder="City name"
              {...register('city')}
              error={errors.city?.message}
            />
            <TextField
              label="Zipcode"
              placeholder="Zipcode"
              {...register('zipcode')}
              error={errors.zipcode?.message}
            />
            <Controller
              name="state"
              control={control}
              render={({field, fieldState}) => (
                <SelectField
                  label="State"
                  placeholder="Select a state"
                  options={states}
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </FormGroup> */}

          <Typography className={styles.header} variant="h3">
            Account
          </Typography>

          <FormGroup columns={3}>
            <TextField
              label="Username"
              placeholder="Username"
              {...register(
                'username',
                !employeeId
                  ? {
                      required: 'Required',
                    }
                  : {}
              )}
              error={errors.username?.message}
            />
            <div style={{position: 'relative'}}>
              <TextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...register(
                  'password',
                  !employeeId
                    ? {
                        required: 'Required',
                      }
                    : {}
                )}
                error={errors.password?.message}
              />
              <img
                onClick={() => setShowPassword((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
            <div style={{position: 'relative'}}>
              <TextField
                label="Password confirmation"
                type={showPassword1 ? 'text' : 'password'}
                placeholder="Password confirmation"
                {...register(
                  'password_confirmation',
                  !employeeId
                    ? {
                        required: 'Required',
                        validate: (val: string) => {
                          if (watch('password') !== val) {
                            return 'Your passwords do no match';
                          }
                        },
                      }
                    : {}
                )}
                error={errors.password_confirmation?.message}
              />
              <img
                onClick={() => setShowPassword1((pr) => !pr)}
                className={styles.eyeIcon}
                src={showPassword1 ? EyeIcon : EyeDisabledIcon}
                alt="eye"
              />
            </div>
          </FormGroup>

          <FormGroup columns={1}>
            <Controller
              name="role"
              control={control}
              rules={{required: 'Required'}}
              render={({field}) => (
                <div>
                  <div
                    style={{
                      marginBottom: 6,
                      cursor: 'pointer',
                      marginTop: 22,
                    }}
                  >
                    Employee Type
                  </div>
                  <SelectField
                    placeholder="Employee Type"
                    options={roles}
                    {...field}
                  />
                </div>
              )}
            />
          </FormGroup>
          {/* {(role === 'Driver (driver app)' ||
            role === 'Technician (driver app)') &&
            !isModal && (
              <Fragment>
                <Typography className={styles.header} variant="h3">
                  {role}
                </Typography>
                <FormGroup columns={1}>
                  <Controller
                    name="team"
                    control={control}
                    render={({field}) => (
                      <div>
                        <div
                          style={{
                            marginBottom: 6,
                            cursor: 'pointer',
                            marginTop: 22,
                          }}
                        >
                          {!isModal ? (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                              }}
                            >
                              <div>Assigned team</div>
                              <PlusComponent
                                onClick={() => {
                                  if (!isModal) setModalVisible(true);
                                }}
                              />
                            </div>
                          ) : (
                            'Assigned team'
                          )}
                        </div>
                        <SelectField
                          placeholder="No assigned team"
                          options={options}
                          {...field}
                        />
                      </div>
                    )}
                  />
                </FormGroup>
              </Fragment>
            )} */}
        </CardContent>
        {!!isModal && (
          <FormActionGroup style={{justifyContent: 'flex-end', padding: 20}}>
            {!!employeeId && (
              <Button
                onClick={() => handleDeleteEmployee(+employeeId)}
                color="danger"
                loading={isDeleting}
              >
                Delete
              </Button>
            )}
            <Button
              onClick={() => {
                if (setHideTeamModal) setHideTeamModal(false);
              }}
              component="button"
              color="gray"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="green" loading={submitting}>
              Save
            </Button>
          </FormActionGroup>
        )}
      </ListWrapper>
      <Modal
        isOpen={modalVisible}
        style={{
          content: {
            maxHeight: '90vh',
            overflow: 'scroll',
            minWidth: 800,
          },
        }}
        onClose={() => setModalVisible(false)}
        onRequestClose={() => setModalVisible(false)}
      >
        <VehicleForm
          setModalVisible={setModalVisible}
          setUser={(data) => {
            setTeam(data);
            setModalVisible(false);
          }}
          isModal={true}
        />
      </Modal>
    </div>
  );
}

export default EmployeeForm;

import {SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useForm, Controller} from 'react-hook-form';
import {useQuery, QueryFunctionContext} from 'react-query';

import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import Button from 'components/Button';
import FormActionGroup from 'components/FormActionGroup';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import SelectField from 'components/Select';

import api from 'api';

import {Driver, ListResponse, User, Vehicle} from 'types';
import {get} from 'lodash';
import Modal from 'components/Modal';
import EmployeeForm from 'pages/EmployeeForm';
import {PlusComponent} from 'pages/Requests/Requests';

interface Option {
  label: string;
  value: number;
}

interface FormValue {
  name: string;
  model: string;
  email: string;
  license_plate_number: string;
  driver?: Option[];
  travel_method?: {
    value: 'car' | 'bike';
    label: string;
  };
}

async function getDrivers() {
  const {data} = await api.get<ListResponse<Driver>>('/drivers/');

  return data;
}

async function getVehicleById({queryKey}: QueryFunctionContext) {
  const [, vehicleId] = queryKey;

  if (!vehicleId) return undefined;

  const {data} = await api.get<Vehicle>(`/teams/${vehicleId}`);

  return data;
}

type Props = {
  isModal?: boolean;
  setUser?: (data: any) => void;
  setModalVisible?: (data: SetStateAction<boolean>) => void;
};

function VehicleForm({
  isModal,
  setUser: setUserModal,
  setModalVisible: setHideMemberModal,
}: Props) {
  const {vehicleId} = useParams<{vehicleId?: string}>();
  const {push, replace} = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
    setValue,
  } = useForm<FormValue>();

  const {data: drivers, refetch} = useQuery('drivers', getDrivers);
  const {data: vehicle} = useQuery(['vehicle', vehicleId], getVehicleById);

  const options = useMemo(
    function () {
      if (!drivers) return [];

      return (
        drivers.results
          // .filter((driver) => !driver.is_assigned)
          .map((d) => ({
            value: d.id,
            label: d.full_name,
          }))
      );
    },
    [drivers]
  );

  useEffect(
    function () {
      if (!vehicle) return;

      reset({
        ...vehicle,
        driver: vehicle.drivers
          ? vehicle.drivers.map((item) => ({
              value: item.id,
              label: item.first_name + ' ' + item.last_name,
            }))
          : undefined,
        travel_method: vehicle.travel_method
          ? {
              value: vehicle.travel_method,
              label: vehicle.travel_method === 'car' ? 'Car' : 'Bike',
            }
          : {
              value: 'car',
              label: 'Car',
            },
      });
    },
    [vehicle, reset]
  );

  async function submit(formValue: FormValue) {
    setSubmitting(true);

    const data = {
      model: formValue.model,
      name: formValue.name,
      drivers: formValue.driver?.map((item) => item.value),
      travel_method: get(formValue, 'travel_method.value', 'car'),
    };

    try {
      if (vehicleId) {
        await api.put(`/teams/${vehicleId}/`, data);
      } else {
        const team = await api.post('/teams/', data);
        if (setUserModal) setUserModal(team.data);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }

    if (!isModal) push('/teams');
  }

  const handleDeleteEmployee = useCallback(
    async (id: number) => {
      try {
        await api.delete(`/teams/${id}/`);
        replace('/teams');
      } catch (error) {}
    },
    [replace]
  );

  const setUser = (data: User) => {
    setModalVisible(false);
    refetch();
    setValue('driver', [
      {
        label: data.first_name + ' ' + data.last_name,
        value: data.id,
      },
    ]);
  };

  return (
    <div>
      <PageHeader>
        <Typography variant="h2">
          {vehicleId ? 'Edit Team' : 'New Team'}
        </Typography>
        {!isModal && (
          <FormActionGroup>
            {!!vehicleId && (
              <Button
                onClick={() => handleDeleteEmployee(+vehicleId)}
                color="danger"
              >
                Delete
              </Button>
            )}
            <Button component="a" to="/teams" color="gray">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(submit)}
              color="green"
              loading={submitting}
            >
              Save
            </Button>
          </FormActionGroup>
        )}
      </PageHeader>
      <FormGroup columns={2}>
        <TextField
          label="Team name"
          placeholder="Name"
          {...register('name', {required: 'Required'})}
          error={errors.name?.message}
        />

        <TextField
          label="Vehicle name"
          placeholder="Vehicle name"
          // {...register('model', {required: 'Required'})}
          error={errors.model?.message}
        />
      </FormGroup>
      {!isModal && (
        <FormGroup columns={1}>
          <Controller
            name="driver"
            control={control}
            render={({field, fieldState}) => (
              <div>
                <div
                  style={{
                    marginBottom: 6,
                    marginTop: 22,
                  }}
                >
                  {!!isModal ? (
                    'Field member'
                  ) : (
                    <div
                      style={{display: 'flex', alignItems: 'center', gap: 10}}
                    >
                      <div>Field member</div>
                      <PlusComponent
                        onClick={() => {
                          if (!isModal) setModalVisible(true);
                        }}
                      />
                    </div>
                  )}
                </div>
                <SelectField
                  placeholder="Select field member"
                  options={options}
                  //@ts-ignore
                  isMulti
                  error={fieldState.error?.message}
                  {...field}
                />
              </div>
            )}
          />
        </FormGroup>
      )}
      <FormGroup columns={1}>
        <Controller
          name="travel_method"
          control={control}
          render={({field, fieldState}) => (
            <div>
              <div
                style={{
                  marginBottom: 6,
                  cursor: 'pointer',
                  marginTop: 22,
                }}
              >
                Travel method
              </div>
              <SelectField
                placeholder="Select travel method"
                options={[
                  {value: 'car', label: 'Car'},
                  {value: 'bike', label: 'Bike'},
                ]}
                error={fieldState.error?.message}
                {...field}
              />
            </div>
          )}
        />
      </FormGroup>
      {!!isModal && (
        <FormActionGroup style={{justifyContent: 'flex-end', marginTop: 20}}>
          {!!vehicleId && (
            <Button
              onClick={() => handleDeleteEmployee(+vehicleId)}
              color="danger"
            >
              Delete
            </Button>
          )}
          <Button
            component="button"
            onClick={() => {
              if (setHideMemberModal) setHideMemberModal(false);
            }}
            color="gray"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(submit)}
            color="green"
            loading={submitting}
          >
            Save
          </Button>
        </FormActionGroup>
      )}
      <Modal
        isOpen={modalVisible}
        style={{
          content: {
            maxHeight: '90vh',
            overflow: 'scroll',
            minWidth: 800,
          },
        }}
        onClose={() => setModalVisible(false)}
        onRequestClose={() => setModalVisible(false)}
      >
        <EmployeeForm
          setModalVisible={setModalVisible}
          setUser={setUser}
          isModal={true}
        />
      </Modal>
    </div>
  );
}

export default VehicleForm;

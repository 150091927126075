import api from 'api';
import {QueryFunctionContext, useQuery} from 'react-query';

type Props = {
  quantity: number;
  id: string;
  fetchable: boolean;
};

async function getPrice({queryKey}: QueryFunctionContext<any>) {
  const [, quantity, id] = queryKey;

  const {data} = await api.get<any>(
    '/payment/stripe-subscription-management/',
    {
      params: {
        quantity,
        new_product_id: id,
      },
    }
  );

  return data;
}

function usePricing({quantity, id, fetchable}: Props) {
  const {data} = useQuery(['price', quantity, id], getPrice, {
    enabled: fetchable,
  });

  return {
    data,
  };
}

export default usePricing;
